.warranty {
  padding-top: 55px;
  padding-bottom: 25px;

  color: $color-default-white;
  background: $dark;

  @include media-breakpoint-up(md) {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  &__title {
    margin-bottom: 22px;
    font-family: $ff-gotham-pro;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 1.2px;
    font-size: 1.875rem;
    color: $color-default-white;
  }

  &__desc {
    margin-bottom: 50px;
    font-size: 12px;
    line-height: 16px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__item {
    max-width: 310px;
    margin: 0 auto 50px;
    font-size: 16px;
    font-family: $ff-pt-sans;

    font-weight: 400;
    line-height: 26px;
    text-align: center;

    & img {
      display: block;

      margin: 0 auto 20px;
    }

    & h3 {
      margin-bottom: 8px;
      color: $color-default-white;
      font-size: 18px;
      line-height: 100%;
      font-family: $ff-proxima-nova;
      text-transform: uppercase;
    }
  }

  &--partners {
    & .warranty {
      &__item {
        & svg {
          margin: 0 auto 22px;
        }
      }

      &__item-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}
