.card {
  background-color: $color-whisper;
  font-size: 1.25rem;

  &__linke {
    font-size: 1.25rem;
  }

  &__top {
    display: flex;
    margin: 22px 0 0;
  }

  &__type {
    margin-right: 54px;
    text-transform: uppercase;
    color: $color-razzmatazz;
    font-weight: 700;
  }

  &__text {
    margin: 0 0 4px;
  }

  &__link {
    font-weight: 400;
    color: $color-nobel;
    transition: text-decoration 0.3s ease;

    &:hover {
      text-decoration: underline;
    }
  }

  &__origin {
    display: flex;
  }

  &__body {
    display: flex;
    column-gap: 20px;
    row-gap: 10px;
    margin: 24px 0 0;
    color: $color-nero;

    &--wrap {
      flex-wrap: wrap;
    }
  }

  &__date {
    display: flex;
  }

  &__info {
    display: flex;
    margin: 28px 0 0;
  }

  &__pages {
    display: flex;
  }

  &__buttons {
    display: flex;
    margin: 32px 0 0;

    @include vp-799 {
      flex-direction: column;
    }
  }

  &__main {
    margin: 40px 0 0;
    background-color: $color-default-white;
    box-shadow: 0 18px 49px 0 rgba(14, 13, 13, 0.08);
  }

  &__slide {
    padding: 20px 0 0;

    @include vp-799 {
      padding: 20px 0 35px;
    }
  }

  & .goods__top {
    padding-left: 85px;
    padding-right: 85px;

    @include vp-1419 {
      position: relative;
      padding: 35px 50px;
      background-color: $color-whisper;
    }

    &:after {
      @include vp-1239 {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: inherit;
        top: 0;
        bottom: 0;
        left: -100%;
        z-index: -1;
        right: -100%;
        left: auto;
      }
    }

    &:before {
      @include vp-1239 {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: inherit;
        top: 0;
        bottom: 0;
        left: -100%;
        z-index: -1;
      }
    }
  }

  & .goods__button {
    @include vp-799 {
      margin-bottom: 30px;
      width: 100%;
    }
  }

  & .goods__title {
    font-size: 2.25rem;
    line-height: 2.625rem;

    @include vp-799 {
      font-size: 1.5625rem;
      line-height: normal;
    }
  }

  &__slide {
    display: none;

    &.is-open {
      display: block;
    }
  }
}
