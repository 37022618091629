.popup {
  position: fixed;
  max-width: 380px;
  background-color: $color-default-white;
  box-shadow: 0 2px 21px 0 rgba(79, 79, 79, 0.33);
  margin: 60px auto 0;
  opacity: 0;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: -1;

  &__banner img {
    text-align: center;
    margin: 0 auto;
  }

  &-lh {
    width: 600px;
    max-width: initial;
    margin-top: 10px;

    & .popup__header {
      padding: 25px 30px 25px 20px;
    }
    @include vp-539 {
      max-width: 90%;
      & .popup__banner {
        & img {
          height: 70px;
        }
      }
    }
  }

  & .select {
    border: 1px solid $color-ruby;
    resize: none;
    width: 100%;
    height: 55px;
  }

  &__loader {
    width: 100%;
    left: 0;
    right: 0;
    max-width: none;
    height: 100%;
    background: transparent;
    margin: 0;
    display: flex;
    align-items: center;
    transform: translate(0);
  }

  &.popup__loader.show {
    z-index: 999999;
  }

  & .select-styled {
    color: $color-default-black;
  }

  &__header {
    background-color: $color-paua;
    padding: 45px 60px 45px 40px;
    color: $color-default-white;

    @include vp-799 {
      padding: 40px 15px;
    }
  }

  &__term {
    & .styled-checkbox {
      color: $color-nobel;

      & + label {
        color: $color-nobel;
        position: relative;

        &:before {
          transition: border 0.3s ease;
          background: 0 0;
          border: 1px solid $color-nobel;
          content: "";
          margin-right: 10px;
          display: inline-block;
          vertical-align: text-top;
          width: 15px;
          height: 15px;
        }

        & a {
          color: $color-paua;
        }
      }

      &:checked {
        & + label {
          position: relative;

          &:after {
            content: "";
            position: absolute;
            left: 3px;
            top: 6px;
            background: $color-default-white;
            width: 2px;
            height: 2px;
            transform: rotate(45deg);
            background: 0 0;
            color: $color-nobel;
            /*             box-shadow: 2px 0 0 $color-nobel, 4px 0 0 $color-nobel, 4px -2px 0 $color-nobel, 4px -4px 0 $color-nobel,
              4px -6px 0 $color-nobel, 4px -8px 0 $color-nobel; */
          }
        }
      }

      &:hover {
        & + label {
          &:before {
            border: 1px solid $color-nobel;
          }
        }
      }
    }
  }

  &__term-error {
    & .error {
      font-size: 1em;
      position: relative;
      top: 10px;
    }
  }

  & .form__group {
    @include vp-799 {
      width: 100%;
    }
  }

  &__overflow {
    &__loader {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100%;

      background: rgba(0, 0, 0, 0.4);
    }
  }

  &__content {
    padding: 20px 20px 35px;
    overflow: hidden;

    & .form__grid {
      margin-top: 0;

      @include vp-799 {
        margin-top: 0;
      }
    }

    & .form__element {
      @include vp-799 {
        max-width: 100%;
      }
    }

    &__loader {
      margin: 0 auto;
      max-width: 380px;
      min-width: 320px;
      width: 100%;
      height: 380px;
      background: $color-default-white url(../img/spinner.gif) center no-repeat;
      z-index: 500;
    }

    & .form__button {
      text-transform: uppercase;
      height: 55px;
      width: 100%;

      @include vp-799 {
        width: 100%;
      }
    }
  }

  &-order {
    & .popup__content .form__button {
      width: 295px;

      @include vp-799 {
        width: 100%;
        max-width: 100%;
      }
    }

    & .form__group {
      flex: 0 0 auto;
      max-width: 100%;
    }

    & .popup__input.form__element {
      width: 295px;
      height: 55px;

      @include vp-799 {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &__input {
    &.form__element {
      @include vp-799 {
        width: 100%;
      }
    }
  }

  &.popup_max {
    & .popup__content {
      padding: 30px 40px 50px;

      @include vp-799 {
        padding: 45px 15px;
      }
    }
  }

  &_large {
    max-width: 580px;
  }

  &_max {
    max-width: 885px;
  }

  &__text {
    font-size: 1.125rem;
    margin: 30px 0 0;
  }

  &__close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 22px;
    height: 22px;
    opacity: 0.3;
    z-index: 100;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      height: 27px;
      width: 1px;
      background-color: $color-default-white;
      transform: rotate(-45deg);
    }

    &:before {
      content: " ";
      position: absolute;
      top: 0;
      height: 27px;
      width: 1px;
      background-color: $color-default-white;
      transform: rotate(45deg);
    }
  }

  &__title {
    line-height: 2rem;
    font-size: 1.75rem;
    color: $color-default-white;
    font-weight: 400;
    font-family: $ff-gotham-pro;

    @include vp-799 {
      font-size: 1.125rem;
      line-height: 1.375rem;
      max-width: 100%;
    }

    &--title {
      text-align: center;
    }

    &.title {
      &_h5 {
        font-size: 1.55rem;
      }
    }

    & .title__text {
      font-weight: 700;
      color: $color-razzmatazz;
    }
  }

  & .form__button {
    font-weight: 700;
  }

  &__send-btn {
    font-weight: 700;
    font-size: 13px;
    width: 100%;
    margin-top: 17px;
    padding-top: 18px;
    padding-bottom: 18px;
    margin-bottom: 15px;
    letter-spacing: 0.04em;
    background-color: $color-sapphire;
    color: $color-default-white;

    &_small {
      max-width: 280px;
    }

    &_large {
      max-width: 310px;
    }
  }

  &__grid {
    display: flex;
    margin-top: 21px;
    align-items: center;
    flex-wrap: wrap; //убрал классы wrap с разметки

    @include vp-799 {
      flex-direction: column;
    }
  }

  &_shade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    visibility: visible;
    opacity: 0;
    z-index: 100;
    pointer-events: none;

    &.show {
      transition: opacity 0.2s;
      opacity: 1;
      z-index: 200;
      pointer-events: all;
    }
  }

  &.show {
    opacity: 1;
    z-index: 300;
    visibility: visible;
    pointer-events: all;
    transition: all 0.3s ease;
  }

  &.success {
    padding: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }

  &__bottom {
    align-items: center;

    & .form__term {
      margin: 0;
    }
  }

  &_subtitle {
    & b {
      color: $color-deep-pink;
      font-weight: bold;
      font-size: 22px;
    }

    & span {
      color: $color-default-white;
      display: block;
      margin-top: 10px;
    }
  }

  & .popup_subtitle {
    text-align: center;
    color: $color-default-white;
    font-size: 18px;
    line-height: 23px;
    margin-top: 30px;
    font-weight: 300;
  }

  &-gift {
    & .popup__header {
      padding-bottom: 0;
    }

    & .popup__image {
      margin: 10px auto 0;
    }

    & .popup__title {
      position: relative;
      font-size: 1.125rem;
      line-height: normal;
    }

    & .popup__content .form__grid {
      margin-top: 0;
    }
  }

  &__image {
    margin: 10px auto 0;
  }
}
