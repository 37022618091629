@import "variables";
@import "global/reboot";

// Vendor
// ---------------------------------

@import "vendor/normalize";

@import "vendor/nouislider";
@import "vendor/select2";
@import "vendor/select2custom";
@import "vendor/selectize";
@import "./vendor/selectric";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/bootstrap-grid";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/tooltip";

// Swiper 7.4.1
// @import "vendor/swiper";

// Global
// ---------------------------------

@import "mixins";
@import "functions";
@import "global/fonts";
@import "global/utils";
@import "global/container";
@import "global/custom";

// Blocks/Custom
// ---------------------------------

@import "blocks/custom/breadcrumbs";
@import "blocks/custom/btn";
@import "blocks/custom/button";
@import "blocks/custom/card";
@import "blocks/custom/carousel";
@import "blocks/custom/catalog";
@import "blocks/custom/dropdown";
@import "blocks/custom/custom-sel";
@import "blocks/custom/features-promo";
@import "blocks/custom/features";
@import "blocks/custom/feedback";
@import "blocks/custom/filter";
@import "blocks/custom/goods";
@import "blocks/custom/hamburger";
@import "blocks/custom/icons";
@import "blocks/custom/info";
@import "blocks/custom/noUi";
@import "blocks/custom/owlCarouserlCustom";
@import "blocks/custom/overlay";
@import "blocks/custom/pagination";
@import "blocks/custom/popular";
@import "blocks/custom/popup";
@import "blocks/custom/price";
@import "blocks/custom/promo";
@import "blocks/custom/ragne";
@import "blocks/custom/search-goods";
@import "blocks/custom/search";
@import "blocks/custom/select";
@import "blocks/custom/form";
@import "blocks/custom/services";
@import "blocks/custom/sidebar";
@import "blocks/custom/banner-first";
@import "blocks/custom/banner-second";
@import "blocks/custom/similar";
@import "blocks/custom/social";
@import "blocks/custom/steps";
@import "blocks/custom/tabs";
@import "blocks/custom/term";
@import "blocks/custom/title";
@import "blocks/custom/error-page";
@import "blocks/custom/reviews";
@import "blocks/custom/achievements";
@import "blocks/custom/servicies";
@import "blocks/custom/calculator";
@import "blocks/custom/working";
@import "blocks/custom/warranty";
@import "blocks/custom/recomendation";
@import "blocks/custom/ideas";

// ---------------------------------

// Blocks
// ---------------------------------

@import "blocks/header";
@import "blocks/main-nav";
@import "blocks/footer";
@import "blocks/custom-input";
@import "blocks/custom-toggle";
@import "blocks/custom-select";
@import "blocks/input-upload";
@import "blocks/jumbotron";

// ---------------------------------

// ❗❗❗ запрещено использовать изображения в css, касается как jpg \ png, так и svg

// ❗ обязательно используйте mixin hover-focus для отключения ховеров на тач устройствах
/* @mixin hover-focus {
    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        @content;
      }
    }

    &.focus-visible:focus {
      @content;
    }
  }
*/
/*  @include hover-focus {
      opacity: 0.8;
    }
*/
// но не используем для текстовых полей ( input, textarea )
// так же в сборке есть отдельный миксин для hover
/*@mixin hover {
    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        @content;
      }
    }
  }
*/
// для focus
/*@mixin focus {
    &.focus-visible:focus {
      @content;
    }
  }
*/
// и для active
/*@mixin active {
    &.focus-visible:active {
      @content;
    }
  }
*/
// адаптив пишем внутри каждого класса (смотрим container.scss)

// для фикса проблем с vh на iOS в сборке подключен скрипт
// используя vh на проекте задавайте их также как в примере в utils.scss

// для любых transition обязательно указывайте transition-property
// transition: $trans-default ❌ ---> transition: color $trans-default ✅
