.error-page {
  margin-top: 50px;
  margin-bottom: 50px;
  min-height: calc(90vh - 144px);

  @media (max-width: 1024px) {
    min-height: auto;
  }

  &__pic {
    display: block;
    margin: 0 auto 8px;
    max-width: 100%;
  }

  &__title {
    text-align: center;
    margin-bottom: 8px;
  }

  &__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    line-height: 24px;
    max-width: 750px;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 120%;
    }

    @media (max-width: 1024px) {
      max-width: 726px;
    }

    & p {
      text-align: center;
    }
  }

  & .btn {
    width: 202px;
    color: $color-default-white;
    background-color: $color-razzmatazz;
    border-color: $color-razzmatazz;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 15.5px 1rem;
    font-size: 1rem;
    line-height: 19px;
    border-radius: 6px;
    font-weight: 700;

    @media (max-width: 768px) {
      width: 202px;
    }
  }
}
