.goods {
  &__price {
    white-space: nowrap;
  }

  &__top {
    padding: 35px 40px 35px 90px;
    font-size: 1.25rem;
    color: $color-nobel;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include vp-1419 {
      padding-left: 45px;
    }

    @include vp-1239 {
      padding: 35px 15px;
    }

    & .search-active {
      display: none;
    }
  }

  &__col {
    display: flex;

    &--wrap {
      flex-wrap: wrap;
      column-gap: 54px;
    }

    &--pages {
      justify-content: flex-end;
      column-gap: 48px;

      @include vp-539 {
        column-gap: 20px;
      }
    }
  }

  &__left {
    @include vp-999 {
      flex-direction: column;
    }
  }

  &__info {
    display: flex;

    @include vp-1419 {
      flex-direction: column;
      margin-right: auto;
      margin-left: 90px;
    }

    @include vp-999 {
      align-items: center;
      flex-direction: row;
      margin: 5px 0;
      order: -1;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 2.25rem;
    line-height: 1;
    text-transform: uppercase;
    font-family: $ff-gotham-pro;
    color: $color-nero;
    margin: 0;
    display: flex;

    @include vp-539 {
      font-size: 1.5625rem;
    }
  }

  &__result {
    font-size: 1.25rem;
    color: $color-default-black;
    font-weight: 700;
  }

  &__header {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 1fr max-content;

    @include vp-999 {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }

  &__item {
    border-top: 1px solid $color-gainsboro;
    padding: 41px 80px 35px 94px;
    background-color: $color-white-smoke;

    @include vp-1419 {
      padding: 35px 45px;
    }

    @include vp-999 {
      padding: 35px 15px;
      display: flex;
      flex-direction: column;
    }
  }

  &__link {
    background-color: $color-paua;
    color: $color-default-white;
    margin-left: 15px;
    transition: background-color 0.3s ease, color 0.3s ease;

    @include vp-799 {
      margin-left: 0;
    }

    &:hover {
      background-color: $color-ruby;
      color: $color-default-white;
    }
  }

  &__more {
    color: $color-nobel;
    border: 1px solid $color-nobel;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: $color-paua;
      color: $color-default-white;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include vp-999 {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__text {
    display: flex;
    color: $color-nobel;
    font-weight: 400;
    flex-shrink: 0;
  }

  &__type {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.06em;

    @include vp-1419 {
      margin-right: 30px;
    }
  }

  &__origin {
    display: flex;
  }

  &__name {
    font-weight: 400;
    font-size: 1.5rem;
    color: $color-paua;
    margin: 28px 0;
    font-family: $ff-gotham-pro;

    @include vp-999 {
      margin: 20px 0 15px;
    }

    @include vp-539 {
      font-size: 1.125rem;
    }
  }

  &__button {
    font-family: $ff-pt-sans;
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 700;
    padding: 16px 20px 15px;
    width: 245px;
    text-align: center;
  }

  &__buttons {
    display: flex;

    @include vp-539 {
      flex-direction: column;
      width: 100%;
    }
  }

  &__right {
    @include vp-539 {
      font-size: 0.875rem;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &__theme {
    @include vp-999 {
      margin: 14px 0;
      flex-basis: 100%;
    }
  }
}
