// @font-face {
//   font-style: normal;
//   font-weight: 400;
//   font-family: "Rouble";

//   font-display: swap;
//   src:
//     url("../build/fonts/rouble.woff2") format("woff2");
// }

@font-face {
  font-family: "Gotham Pro";
  src: url("../../build/fonts/GothamPro-BoldItalic.woff2") format("woff2");
  src: url("../../build/fonts/GothamPro-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../build/fonts/GothamPro-Italic.woff2") format("woff2");
  src: url("../../build/fonts/GothamPro-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../build/fonts/GothamPro-Medium.woff2") format("woff2");
  src: url("../../build/fonts/GothamPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../build/fonts/GothamPro-BlackItalic.woff2") format("woff2");
  src: url("../../build/fonts/GothamPro-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../build/fonts/GothamPro-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro Narrow";
  src: url("../../build/fonts/GothamProNarrow-Bold.woff2") format("woff2");
  src: url("../../build/fonts/GothamProNarrow-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro Narrow";
  src: url("../../build/fonts/GothamProNarrow-Medium.woff2") format("woff2");
  src: url("../../build/fonts/GothamProNarrow-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../build/fonts/GothamPro-LightItalic.woff2") format("woff2");
  src: url("../../build/fonts/GothamPro-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../build/fonts/GothamPro-Light.woff2") format("woff2");
  src: url("../../build/fonts/GothamPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../build/fonts/GothamPro-Black.woff2") format("woff2");
  src: url("../../build/fonts/GothamPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../build/fonts/GothamPro.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../build/fonts/GothamPro-MediumItalic.woff2") format("woff2");
  src: url("../../build/fonts/GothamPro-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "PT Sans";
  src: url("../../build/fonts/PTSans-Italic.woff2") format("woff2");
  src: url("../../build/fonts/PTSans-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "PT Sans";
  src: url("../../build/fonts/PTSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PT Sans";
  src: url("../../build/fonts/PTSans-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "PT Sans";
  src: url("../../build/fonts/PTSans-BoldItalic.woff2") format("woff2");
  src: url("../../build/fonts/PTSans-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
