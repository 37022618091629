.search-goods {
  max-width: 80%;
  display: none;
  position: relative;
  padding: 0;

  @include vp-539 {
    max-width: 100%;
  }

  &.search-show {
    display: block;

    @include vp-539 {
      width: 100%;
    }
  }

  &__input {
    width: 100%;
    border: none;
    padding: 10px 30px;
    border-bottom: 1px solid $color-default-black;
    outline: 0;
  }

  &__submit {
    position: absolute;
    top: 50%;
    right: 5px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  &__close {
    position: absolute;
    width: 10px;
    height: 10px;
    padding: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
  }

  &__close {
    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 10%;
      left: 50%;
      height: 20px;
      width: 1px;
      background-color: $color-default-black;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:after {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}
