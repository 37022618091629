.header {
  padding: 15px 0;
  background-color: $color-default-white;

  &__container {
    @media (min-width: 1200px) {
      max-width: 1826px;
    }
  }

  &__grid {
    align-items: center;
    justify-content: space-between;
    display: flex;

    @include vp-999 {
      flex-direction: column;
    }
  }

  &__col {
    display: flex;

    @include vp-999 {
      justify-content: center;
      flex: 1;
      width: 100%;
    }
  }

  &__button {
    position: relative;
    font-size: 1.25rem;
    font-weight: 700;

    &_order {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;

      @include vp-999 {
        width: 100%;
      }
    }

    &_info {
      border: 1px solid $color-razzmatazz;
      margin-left: 10px;
      color: $color-default-black;
      text-align: center;

      &:after {
        content: 'новой работы';
        position: absolute;
        font-size: 10px;
        color: inherit;
        bottom: 1px;
        left: 50%;
        transform: translate(-50%,0);

        @include vp-1419 {
          font-size: 8px;
          bottom: 0;
        }

        @include vp-999 {
          content: none;
        }
      }

      @include vp-1419 {
        font-size: 1.125rem;
        line-height: 1;
      }

      @include vp-999 {
        margin-left: 15px;
        padding: 5px 15px;
      }

      & .button__text {
        @include vp-1419 {
          display: block;
        }
      }
    }

    & .select-styled {
      top: 45%;
      transform: translateY(-50%);
      left: 50px;
      right: 0;
      font-weight: 700;
      font-size: 1.125rem;
    }
  }

  &__center {
    @include vp-999 {
      order: -1;
    }
  }

  &__right {
    margin-left: 10px;

    @include vp-999 {
      order: -1;
      margin: 20px 0 30px;
    }
  }

  &__hamburger {
    display: flex;

    &.button {
      width: 313px;
      position: relative;
      border: 1px solid $color-razzmatazz;
      padding: 10px 15px;
      justify-content: flex-start;

      @include vp-999 {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }

    & span {
      margin-left: 16px;
    }
  }

  &__logo {
    max-width: 100%;
    width: 190px;
  }

  &__social {
    display: flex;

    @include vp-1239 {
      display: none;
    }

    &.social {
      margin-left: 40px;
    }
  }

  & .filter {
    & .form__element {
      border: none;
    }
  }
}
