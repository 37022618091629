.form {
  position: relative;

  &__files {
    &__item {
      margin-bottom: 13px;
      padding-left: 26px;
      padding-right: 26px;
      color: $color-heather;
    }

    &-error {
      color: $color-red-orange;
    }
  }

  & textarea {
    font-family: inherit;
  }

  &__field-wrapper {
    position: relative;
  }

  &__field-list-courses {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    position: absolute;
    top: calc(100% - 25px);
    left: 0;
    right: 0;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    background-color: #fff;
    border: solid 1px #f32478;
    box-shadow: 0 0 30px rgba(74, 74, 74, 0.15);
    list-style-type: none;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.3s ease, opacity 0.3s ease;
    min-height: 30px;
    z-index: 10;

    &--opened {
      visibility: visible;
      opacity: 1;
    }
  }

  &__field-item-courses-highlighted {
    color: #000 !important;
    font-weight: 700;
    pointer-events: none;
  }

  &__field-item-courses {
    font-size: 14px;
    line-height: 1;
    color: #5f6061;
    font-weight: 600;
    padding: 8px 26px;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease;
    word-wrap: break-word;

    &--disabled {
      cursor: none;
      pointer-events: none;
    }

    &:hover {
      color: #000;
      background-color: #f2f2f2;
    }
  }

  &__textfield {
    width: 100%;
    min-height: 126px;
    margin-bottom: 22px;
    padding: 16px 26px;
    box-sizing: border-box;

    font-size: 16px;
    line-height: 1;

    color: $color-default-black;
    border: 1px solid $color-default-black;
    background: $color-default-white;

    &::placeholder {
      font-weight: 700;
      font-family: $ff-proxima-nova;

      text-transform: uppercase;

      color: inherit;
    }
  }

  &__field {
    width: 100%;
    margin-bottom: 13px;
    padding: 16px 26px;
    box-sizing: border-box;
    text-overflow: ellipsis;

    font-size: 16px;
    line-height: 1;

    color: $color-default-black;
    border: 1px solid $color-deep-cerise;
    border-radius: 0;
    background: $color-default-white;
    text-transform: uppercase;

    &.error {
      border-color: $red;

      &::placeholder {
        color: $red !important;
      }
    }

    &::placeholder {
      font-weight: 700;
      font-family: $ff-proxima-nova;
      text-transform: uppercase;
      color: $color-heather;
    }

    &__label {
      font-size: 12px;
      line-height: 16px;

      letter-spacing: 0.04em;

      & a {
        text-decoration: underline;
        transition: color 0.3s ease;
        color: inherit;

        &:hover {
          color: $green;
        }
      }
    }

    &-group-files {
      position: relative;
      display: block;

      width: 100%;
      min-height: 52px;
      margin-bottom: 13px;
      padding: 16px 26px;
      box-sizing: border-box;

      font-size: 16px;
      line-height: 1;

      background: $color-default-white;
      cursor: pointer;

      &::after {
        content: attr(data-placeholder);

        font-weight: 700;
        font-family: $ff-proxima-nova;

        text-transform: uppercase;

        color: $color-heather;
      }

      &:before {
        content: "";
        display: block;
        width: 10px;
        height: 18px;
        background: url("../img/download.svg") no-repeat 50% 50%;
        position: absolute;
        top: 15px;
        right: 20px;
      }

      & input {
        display: none;
      }
    }

    &--search {
      padding: 9.5px 26px;
      font-size: 14px;
      font-family: $ff-proxima-nova;
      font-weight: 400;
      text-transform: none;
      background-image: url("../img/icons/search.svg");
      background-position: 98% 50%;
      background-repeat: no-repeat;

      &::placeholder {
        font-weight: 400;
        font-family: $ff-proxima-nova;

        text-transform: none;

        color: $color-heather;
      }
    }
  }

  &__bottom {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }

  &__file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  &.form-inline {
    width: 100%;
  }

  &-file {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &__top {
    margin-bottom: 20px;
  }

  &__row {
    -webkit-box-align: center;
    margin-left: -7px;
    margin-right: -7px;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;

    &.ta-r {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__group {
    padding-left: 7px;
    padding-right: 7px;
    margin-bottom: 15px;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 33.33%;
    flex: 1 0 33.33%;
    max-width: 33.33%;

    & .form__term {
      margin: 0;
    }

    &_small {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 25%;
      flex: 1 0 25%;
      max-width: 25%;
      min-width: 260px;
    }

    &_large {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
      flex: 1 1 100%;
      max-width: 100%;

      &.form__group--custom {
        width: 25%;
        max-width: 25%;
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;

        @include vp-799 {
          max-width: 100%;
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }

    &_middle {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 50%;
      flex: 1 0 50%;
      max-width: 50%;
    }

    &_align-end {
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-item-align: end;
      align-self: flex-end;
    }

    &_time {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      & span {
        width: 27px;
        text-align: center;
      }
    }
  }

  &__element {
    border: 1px solid $color-ruby;
    resize: none;
    outline: 0;
    height: 56px;
    color: $color-default-black;
    width: 100%;
    background-color: $color-default-white;
    font-size: 18px;
    padding: 0 12px;

    &--custom {
      border: none;
      height: 55px;

      @include vp-1239 {
        height: 60px;
      }
    }

    &::-moz-placeholder {
      color: $color-nobel;
    }

    &::-webkit-input-placeholder {
      color: $color-nobel;
    }

    &:-ms-input-placeholder {
      color: $color-nobel;
    }

    &::-ms-input-placeholder {
      color: $color-nobel;
    }

    &::placeholder {
      color: $color-nobel;
    }
  }

  &__title {
    margin: 0 0 13px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }

  &__text {
    font-size: 1.25rem;
    text-transform: uppercase;

    &_bold {
      font-weight: 700;
    }

    & span {
      font-weight: 700;
      color: $color-razzmatazz;
    }
  }

  &__label {
    margin-top: 6px;
    margin-bottom: 0;
    font-size: 0.875rem;
    width: 100%;
  }

  &__select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    & option {
      text-transform: none;
    }
  }

  &__time {
    width: calc((100% - 27px) / 2);
    cursor: pointer;
  }

  &__textarea {
    height: 55px;

    &_large {
      height: 90px;
      padding: 12px 10px;
    }
  }

  &__grid {
    margin-top: 38px;
  }

  &__desc {
    color: $color-default-white;
  }

  &__term {
    color: $color-silver;
    margin-top: 15px;
  }

  &__checkbox {
    &:checked {
      opacity: 0;
      z-index: 10;
      width: 150px;
      height: 25px;
      cursor: pointer;
      position: absolute;

      & + label {
        display: inline-block;
        position: relative;
        padding-left: 40px;
        line-height: 25px;
        cursor: pointer;
        font-size: 16px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 25px;
          height: 25px;
          background-color: $color-sapphire;
        }

        &:after {
          left: 8px;
          top: 9px;
          width: 11px;
          height: 6px;
          border-radius: 1px;
          border-left: 2px solid $color-default-white;
          border-bottom: 2px solid $color-default-white;
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
          content: "";
          position: absolute;
          -webkit-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
      }
    }

    &:not(:checked) {
      opacity: 0;
      z-index: 10;
      width: 150px;
      height: 25px;
      cursor: pointer;
      position: absolute;

      & + label {
        display: inline-block;
        position: relative;
        padding-left: 40px;
        line-height: 25px;
        cursor: pointer;
        font-size: 16px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 25px;
          height: 25px;
          background-color: $color-sapphire;
        }

        &:after {
          content: "";
          position: absolute;
          -webkit-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
      }
    }
  }

  &__radiobutton {
    &:checked {
      opacity: 0;
      z-index: 10;
      width: 150px;
      height: 20px;
      cursor: pointer;
      position: absolute;

      & + label {
        display: inline-block;
        position: relative;
        padding-left: 28px;
        line-height: 20px;
        cursor: pointer;
        font-size: 16px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          background-color: $color-sapphire;
          border-radius: 100%;
        }

        &:after {
          content: "";
          position: absolute;
          -webkit-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          transition: all 0.2s ease;
          left: 5px;
          top: 5px;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: $color-default-white;
        }
      }
    }

    &:not(:checked) {
      opacity: 0;
      z-index: 10;
      width: 150px;
      height: 20px;
      cursor: pointer;
      position: absolute;

      & + label {
        display: inline-block;
        position: relative;
        padding-left: 28px;
        line-height: 20px;
        cursor: pointer;
        font-size: 16px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          background-color: $color-sapphire;
          border-radius: 100%;
        }

        &:after {
          content: "";
          position: absolute;
          -webkit-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
      }
    }
  }

  &__radio-item {
    margin-bottom: 7px;
  }
}
