@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation-duration: 0s !important;
    animation-duration: 0s !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
    -webkit-transition-duration: 0s !important;
    -o-transition-duration: 0s !important;
    transition-duration: 0s !important;
    scroll-behavior: auto !important;
  }
}

:root .row {
  margin-left: -10px;
  margin-right: -10px;
  list-style: none;

  &.goods__header,
  &.goods__bottom {
    margin-left: 0;
    margin-right: 0;
  }
}

.styled-checkbox {
  color: $color-nobel;

  &.black {
    & + label:before,
    &:checked + label:before,
    &:hover + label:before {
      border: 1px solid $color-dim-gray;
      background: 0 0;
    }

    & + label {
      padding: 0;
      color: $color-dim-gray;
      font-size: 0.75rem;
    }

    & + label a {
      color: $color-dim-gray;
      text-decoration: underline;
    }

    & + label:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 15px;
      height: 15px;
      transition: background-color 0.3s ease, border 0.3s ease;
    }
  }

  & + label {
    color: $color-default-white;

    &:before {
      transition: color 0.3s ease;
      border: 1px solid $color-nobel;
    }

    & a {
      color: $color-default-white;
      text-decoration: underline;
    }
  }

  &:checked {
    & + label {
      &:before {
        background: 0 0;
      }

      &:after {
        background: 0 0;
        color: $color-nobel;
        /*         box-shadow: 2px 0 0 $color-nobel, 4px 0 0 $color-nobel, 4px -2px 0 $color-nobel, 4px -4px 0 $color-nobel,
          4px -6px 0 $color-nobel, 4px -8px 0 $color-nobel; */
      }
    }
  }

  &:hover {
    & + label {
      &:before {
        border: 1px solid $color-nobel;
      }
    }
  }
}

.bg-grey {
  overflow: auto;

  background-color: $light;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.bg-social {
  @include media-breakpoint-up(lg) {
    overflow: auto;

    /*     background: url("../img/achievements-bg.svg") no-repeat 50% 10%;
    background-size: 100% auto; */
  }
}

.mt-15 {
  margin-top: 15px;
}

label.error {
  font-size: 0.65em;
  color: $color-dark-gray;
}

.error-block {
  text-align: center;
  margin-top: 15px;

  & label {
    display: block;
    width: 100%;
    color: $color-razzmatazz;
    font-weight: 600;
  }
}

.sendToCrmFromMain {
  & .error-block {
    margin-top: 0;
  }

  & .search__term {
    &.term {
      margin-bottom: 15px;
    }
  }
}
.sendToCrmTop {
  & .error-block {
    margin-top: 15px;
    & {
      label {
        color: $color-default-white;
      }
    }
  }
}

.ta-r {
  text-align: right;
}

.link-gray {
  color: $color-nobel;
}

.select-hidden,
.visually-hidden {
  visibility: hidden;
}

.no-js {
  & .owl-carousel {
    display: block;
  }
}

input {
  -webkit-appearance: none;
}

.col {
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
  flex-grow: 0;
  flex-basis: auto;
  max-width: none;
}

.col-1 {
  width: 8.33333333%;
}

.col-2 {
  width: 16.66666667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.66666667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.66%;
}

.col-12 {
  width: 100%;
}

.col-20 {
  width: 20%;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;

  &_white {
    min-height: auto;
    background-color: $color-default-white;
  }

  &_black {
    background-color: $color-default-black;
    min-height: auto;
  }
}

.main {
  position: relative;
  flex: 1;
}

.modal-h1 {
  position: relative;
}

form {
  &.form-inline {
    width: 100%;
  }
}

.switch_modal {
  position: absolute;
  background-color: $color-default-white;
  width: 96%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
  padding: 32px 78px;
  top: -99999px;
  box-shadow: 9px 14px 26px 0px rgb(0 0 0 / 15%);
  transform: translate(0, -10px);
  opacity: 0;
  transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out;
}

.switch_modal:before {
  content: "";
  border: 13px solid transparent;
  border-bottom: 9px solid white;
  border-left: 20px solid white;
  position: absolute;
  left: 0px;
  top: -22px;
}

a.switch_modal__ul__li__link {
  font-size: 14px;
  color: $color-default-black;
}

ul.switch_modal__ul {
  display: flex;
  flex-wrap: wrap;
}

li.switch_modal__ul__li {
  width: 25%;
}

.switch_modal.open {
  opacity: 1;
  pointer-events: inherit;
  transform: translate(0, 0px);
  top: 274px;
}

/*Модалка*/
.modal {
  .job-form-input input {
    margin: 18px;
    line-height: 40px;
    border-radius: 0px;
    border: initial;
    padding: 0 20px;
    color: rgba(0, 0, 0, 0.6196078431372549);
    outline: initial;
    border-bottom: 1px solid;
  }
}

.close-modal {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -16px;
  top: -14px;
}

.modal-checkbox-1 {
  display: block;
  max-width: 250px;
  margin: 10px 0;
}

.ripple {
  position: relative;
  overflow: hidden;
}

.ripple-effect {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 5000px;
  -webkit-animation: ripple 1s ease forwards;
  animation: ripple 1s ease forwards;
  z-index: 3333;
}

@-webkit-keyframes ripple {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(100);
  }
}

@keyframes ripple {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(100);
  }
}

.input_error {
  border-bottom: 1px solid rgba(255, 0, 0, 0.6196078431372549) !important;
}

.input_error_footer {
  border: 1px rgb(255, 0, 0) solid !important;
}

.succes-send {
  display: none;
}

.hidden {
  display: none;
}

.open-succes {
  display: block;
}

i.fas.fa-times.close-modal {
  position: absolute;
  right: 2px;
  top: 3px;
  font-size: 16px;
  cursor: pointer;
  z-index: 9999;
}

.wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.responsive-img {
  max-width: 100%;
  height: auto;
}

.overflow {
  overflow: hidden;
}

.sticky {
  position: fixed;
  z-index: 101;
}

.stop {
  position: relative;
  z-index: 101;
}

@include vp-1279 {
  .title_h1,
  h1 {
    font-size: 34px;
    line-height: 40px;
  }

  .title_h2,
  h2 {
    font-size: 30px;
    line-height: 36px;
  }
}

.h2 {
  margin-bottom: 30px;

  font-weight: 900;
  font-size: 16px;
  line-height: 150%;
  font-family: "GothamPro", Verdana, Arial, sans-serif;

  letter-spacing: 0.04em;
  text-transform: uppercase;

  @media (min-width: 992px) {
    margin-bottom: 30px;

    font-size: 24px;
  }
  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 50px;
  }
}

@include vp-479 {
  .title_h1,
  h1 {
    font-size: 22px;
    line-height: 28px;
  }

  .title_h2 {
    font-size: 25px;
    line-height: 30px;
  }
}

.box-center {
  text-align: center;
}

.visually-hidden_clip {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.menu__link {
  color: $color-default-white;
}

.main-delivery {
  &__inner {
    background-color: $color-sapphire;

    &_blue {
      background-color: $color-sapphire;
    }
  }
}

.btn-file {
  border: 1px solid $color-sapphire;
  font-size: 0.625rem;
  padding: 8px;
  margin-right: 15px;
}

.file {
  &__text {
    font-size: 0.875rem;

    &_bold {
      font-weight: 700;
    }
  }
}

.banner-down {
  display: none;

  @include vp-1239 {
    .banner-first__image {
      width: 40%;
      right: 0;
      left: auto;
    }
  }
}

@include vp-999 {
  .switch_modal.open {
    top: 44px;
  }

  .switch_modal:before {
    display: none;
  }
}

@include vp-799 {
  li.switch_modal__ul__li {
    width: 50%;
  }

  .switch_modal {
    padding: 32px 18px;
  }

  .switch_modal.open {
    top: 103px;
  }
}

@include vp-539 {
  li.switch_modal__ul__li {
    width: 100%;
  }

  .banner-down .banner-first__image {
    width: 100%;
  }
}

.hidden-link {
  display: none;
}

.hiddenCalcForm {
  display: none;
}

.btnCalculate {
  /* display: none; */
  position: fixed;
  right: 36px;
  bottom: 130px;
  background: $blue;
  color: $color-default-white;
  width: 180px;
  font-size: 16px;
  border-radius: 110px;
  cursor: pointer;
  transition: 0.2s;
  z-index: 100;
  transition: background-color 0.3s ease;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 34px;
    top: 13px;
    left: 19px;
    height: 34px;
    background: url(../img/icons/calculator.svg);
    background-size: cover;
  }

  &:hover {
    background: $color-fire-engine-red;
  }

  & span {
    width: 95px;
    display: block;
    line-height: 15px;
    padding: 14px 0 14px 60px;
    font-weight: bold;
  }
}

.selectric {
  border-radius: 0 !important;
}

.selectric-form__field.error .selectric span {
  color: $color-amaranth;
}

span.error {
  display: none !important;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-right: 0;
  padding-left: 0;
  max-width: none;
}
