.hamburger {
  padding: 9px 8px 7px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  -o-transition-property: opacity, filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.15s;
  -o-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  overflow: visible;

  &__list {
    position: absolute;
    top: calc(100%);
    width: calc(100% + 2px);
    max-height: 400px;
    overflow-y: scroll;
    left: -1px;
    right: 0;
    z-index: 999;
    display: none;
    background-color: $color-default-white;
    border: 1px solid $color-razzmatazz;
    border-top: transparent;

    &.show {
      display: block;
    }
  }

  &__li {
    color: $color-default-black;
  }

  &__link {
    width: 100%;
    border-top: 1px solid $color-nobel;
    padding: 10px 20px;
    font-size: 1.125rem;
    color: inherit;
    transition: background-color .3s ease, color .3s ease;

    &:hover {
      background-color: $color-razzmatazz;
      color: $color-default-white;
    }
  }

  &__text {
    margin-left: 10px;
    font-weight: 700;

    @include vp-1419 {
      font-size: 1.125rem;
    }
  }

  &.is-active {
    & .hamburger-inner {
      background-color: $color-default-black;

      &::after {
        background-color: $color-default-black;
      }

      &::before {
        background-color: $color-default-black;
      }
    }
  }

  &--3dx {
    & .hamburger-box {
      -webkit-perspective: 80px;
      perspective: 80px;
      -webkit-transition: background-color 0s 0.1s
          cubic-bezier(0.645, 0.045, 0.355, 1),
        -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
        -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      -o-transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
        -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:after {
        -webkit-transition: -webkit-transform 0s 0.1s
          cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: -webkit-transform 0s 0.1s
          cubic-bezier(0.645, 0.045, 0.355, 1);
        -o-transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
          -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &:before {
        -webkit-transition: -webkit-transform 0s 0.1s
          cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: -webkit-transform 0s 0.1s
          cubic-bezier(0.645, 0.045, 0.355, 1);
        -o-transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
          -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    &.is-active {
      & .hamburger-inner {
        background-color: transparent !important;
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);

        &::before {
          -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
          transform: translate3d(0, 10px, 0) rotate(45deg);
        }

        &::after {
          -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg);
        }
      }
    }
  }

  &-box {
    width: 30px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  &-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
    width: 30px;
    height: 3px;
    background-color: $color-default-black;
    position: absolute;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;

    &::after {
      content: "";
      display: block;
      bottom: -10px;
      width: 30px;
      height: 3px;
      background-color: $color-default-black;
      position: absolute;
      -webkit-transition-property: -webkit-transform;
      transition-property: -webkit-transform;
      -o-transition-property: transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      -webkit-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease;
    }

    &::before {
      content: "";
      display: block;
      top: -10px;
      width: 30px;
      height: 3px;
      background-color: $color-default-black;
      position: absolute;
      -webkit-transition-property: -webkit-transform;
      transition-property: -webkit-transform;
      -o-transition-property: transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      -webkit-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease;
    }
  }
}
