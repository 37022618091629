.info {
  padding: 40px 0;

  &__container {
    max-width: 1755px;
    width: 100%;
    padding: 0 15px;

    @include vp-1419 {
      max-width: 100%;
    }

    @include vp-1239 {
      max-width: 970px;
    }

    @include vp-999 {
      max-width: 100%;
    }

    @include vp-799 {
      max-width: 100%;
    }
  }
}
