.jumbotron {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;

  background-color: $light;

  @include media-breakpoint-up(md) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  & > .container {
    & > .row {
      & > .col-12 {
        padding: 20px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &--servicies,
  &--service {
    padding-bottom: 0;
    padding-top: 0;
    flex-direction: column;
    min-height: 454px;

    @media (min-width: 768px) {
      padding-top: 0;
      padding-bottom: 0;
    }

    & .container {
      display: flex;
      flex-grow: 1;
      padding-top: 0;
      padding-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px;
      min-height: 200px;
      height: 100%;
      background-color: transparent;

      @media (min-width: 768px) {
        background-position: 140% 100%;
        background-size: auto 454px;
        padding-top: 0;
        padding-bottom: 0;
        max-width: 100%;
      }

      @media (min-width: 992px) {
        background-position: 100% 100%;
        padding-top: 0;
        padding-bottom: 0;
        max-width: 960px;
      }

      @media (min-width: 1200px) {
        max-width: 1140px;
      }

      @include vp-1239 {
        max-width: 1000px;
      }

      & .row {
        display: flex;
        align-items: center;
        width: 100%;
        //min-height: 100%;
        margin: 0 auto;

        @media (max-width: 991px) {
          max-width: 758px;
        }
      }
    }

    & .jumbotron__title {
      text-align: left;
      font-family: $ff-gotham-pro;
      font-size: 4.5rem;
      color: $color-default-white;
    }
  }

  &--servicies {
    background-image: url("../img/bg-girl-services.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &--service {
    background-image: url("../img/bg-inside.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    & .jumbotron__innerpage {
      max-width: 50%;

      @include vp-999 {
        max-width: 70%;
      }

      @include vp-799 {
        max-width: 100%;
      }
    }
  }

  &__innerpage {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__breadcrumbs {
    margin-bottom: 30px;
    //padding-left: 10px;

    font-size: 14px;
    line-height: 120%;
    max-width: 680px;

    /* @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 120%;
    } */

    & a {
      color: inherit;
    }

    &--worktape {
      margin-bottom: 47px;

      /*       @include media-breakpoint-up(md) {
        margin-bottom: 38px;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 60px;
      } */
    }
  }

  &__suptitle {
    margin-bottom: 12px;

    font-weight: 900;
    letter-spacing: 1.2px;
    font-size: 1.875rem;
    line-height: 150%;
    font-family: $ff-gotham-pro;

    text-transform: uppercase;
    color: $color-default-white;

    /*     @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 150%;
      margin-bottom: 50px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 30px;
      line-height: 150%;
    } */
  }

  &__parallax {
    position: absolute;
    top: 0;
    right: 0;

    display: none;

    width: 100%;
    max-width: 1200px;
    height: 100%;
    /*
    @include media-breakpoint-up(lg) {
      display: block;
    } */

    &__item {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      &--1 {
        z-index: 4;

        background: url("../img/parallax/1.png") no-repeat 80% 60%;
      }
      &--2 {
        z-index: 3;

        background: url("../img/parallax/2.png") no-repeat 0 0;
      }
      &--3 {
        z-index: 2;

        background: url("../img/parallax/3.png") no-repeat 85% 0;
      }
    }
  }

  &__title,
  &__title h1 {
    position: relative;
    z-index: 10;

    margin-bottom: 16px;

    font-weight: 700;
    font-size: 36px;
    line-height: 100%;
    font-family: $ff-gotham-pro;

    text-align: center;
    letter-spacing: -0.02em;
    text-transform: none;
    color: $blue;

    /*     @include media-breakpoint-up(md) {
      font-size: 72px;
      line-height: 1;

      text-align: left;
    } */
  }

  &__desc {
    position: relative;
    z-index: 10;
    color: $color-default-white;

    max-width: 340px;
    margin-bottom: 0;

    font-size: 14px;
    line-height: 120%;

    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0;

      text-align: left;
    }

    &--full {
      max-width: 100%;
    }

    &__statistics {
      margin: 15px 0;

      font-size: 12px;
      line-height: 15px;
      text-align: left;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 22px;
        margin: 30px 0 0;
      }

      &:nth-child(2n + 1) {
        padding-right: 30px;
      }

      & h4 {
        position: relative;
        margin-bottom: 18px;
        font-size: 18px;
        line-height: 100%;
        font-family: $ff-proxima-nova !important;
        color: $color-default-white;

        @include media-breakpoint-up(md) {
          font-size: 24px;
          line-height: 100%;
          margin-bottom: 16px;
        }

        &:after {
          content: "";
          width: 100%;
          height: 1px;
          background: rgba(255, 255, 255, 0.5);
          bottom: -9px;
          left: 0;
          position: absolute;
        }
      }

      & .delimtr {
        margin-bottom: 11px;
        @include media-breakpoint-up(md) {
          margin-bottom: 8px;
        }
      }
    }

    & ul {
      list-style: none outside;

      font-size: 14px;
      line-height: 17px;
      text-align: left;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 22px;
      }

      & li {
        position: relative;

        margin-bottom: 8px;
        padding-left: 32px;

        @include media-breakpoint-up(md) {
          margin-bottom: 22px;
        }

        &:before {
          content: "";

          position: absolute;
          top: 0;
          left: 0;

          display: block;

          width: 20px;
          height: 20px;

          background: url("../img/icons/good.svg") no-repeat 50% 50%;
        }
      }
    }
  }

  &__reviews {
    position: relative;
    z-index: 10;

    display: flex;
    align-items: flex-start;

    width: 440px;
    margin-top: 20px;

    &__pic {
      padding-right: 24px;
      img {
        width: 180px;
        height: 189px;
        max-width: none;
      }
    }

    &__desc {
      //padding-top: 60px;

      font-size: 14px;
      line-height: 120%;
      padding: 50px 0 0;
    }
  }

  &__filter {
    position: relative;
    z-index: 10;

    margin-top: 100px;
    padding: 20px 10px;

    border-radius: 6px;
    background-color: $color-default-white;

    max-width: 988px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 258px;

    & span.error {
      display: none !important;
    }

    & .selectric-items {
      border-radius: 6px;
      top: auto;
      bottom: 100%;
    }

    & .selectric-form__field {
      & .selectric {
        height: 100%;
      }
    }

    & .row {
      margin-left: -8px;
      margin-right: -8px;

      > .col,
      > [class*="col-"] {
        padding-right: 8px;
        padding-left: 8px;
      }
    }

    @include media-breakpoint-up(md) {
      margin-top: 230px;
      padding: 46px 56px 33px;
      margin-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 20px;
      padding: 46px 66px 33px;
    }

    & .form__field {
      margin-bottom: 22px;
    }

    &__tooltip {
      position: absolute;
      top: -84px;
      right: 8px;

      padding: 18px 19px;

      border-radius: 6px;
      background-color: $color-default-white;
      font-size: 16px;
      line-height: 19px;
      z-index: 50;

      @include media-breakpoint-up(md) {
        right: auto;
        left: 0;
      }
      @include media-breakpoint-up(lg) {
        right: 66px;
        left: auto;
      }

      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

      &:before {
        content: "";

        position: absolute;
        top: -16px;
        right: -8px;

        display: block;

        width: 46px;
        height: 46px;

        border-radius: 6px;
        background: rgba(10, 113, 204, 0.35) url("../img/good.svg") no-repeat 50% 50%;

        backdrop-filter: blur(10px);
      }

      &:after {
        content: "";

        position: absolute;
        right: 10px;
        bottom: -8px;

        display: block;

        width: 0;

        border-top: 8px solid $color-default-white;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
      }
    }

    &__agreement {
      width: 100%;
      padding-top: 8px;

      font-size: 12px;

      letter-spacing: 0.04em;
      & a {
        color: black;
      }
      @include media-breakpoint-up(md) {
        position: absolute;
        bottom: 20px;
        left: 0;

        padding-top: 0;

        text-align: center;
      }

      & input.error {
        & + label {
          color: $red;

          & a {
            color: inherit;
          }
        }
      }

      & input[type="checkbox"]:checked + .filter-agreement__label,
      & input[type="checkbox"]:not(:checked) + .filter-agreement__label {
        &:after,
        &:before {
          top: 50%;
          transform: translate(0, -50%);
        }

        &:after {
          left: 9px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
