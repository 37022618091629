.search {
  &__input {
    background-color: $color-default-white;
    width: 90%;
    height: 55px;
    padding: 0 30px;
    font-size: 1.25rem;
    color: $color-nobel;
    outline: 0;
    border: none;

    @include vp-999 {
      width: 80%;
      height: 60px;
    }

    @include vp-799 {
      width: 100%;
      margin-bottom: 20px;
    }

    &_main {
      width: 50%;

      @include vp-1239 {
        width: 33.33%;
      }

      &--custom {
        width: 25%;
        margin-left: 20px;

        @include vp-799 {
          margin-left: 0;
        }
      }
    }

    &_second {
      margin-left: 20px;
      width: 25%;

      @include vp-1239 {
        width: 33.33%;
      }
    }
  }

  &__button {
    margin-left: 20px;
    color: $color-default-white;
    flex: 1;
    background-color: $color-paua;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.25rem;
    padding: 0 30px;
    border-radius: 0;
    border-color: transparent;
    transition: background-color 0.3s ease;

    @include vp-799 {
      margin-left: 0;
      padding: 16px;
    }

    &:hover {
      background-color: $color-ruby;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 11px 0 0;
    font-size: 0.875rem;
  }

  &__popular {
    color: $color-default-white;
    line-height: 1;

    @include vp-799 {
      display: none;
    }

    & span {
      color: $color-paua;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__form {
    display: flex;

    @include vp-799 {
      flex-direction: column;
    }
  }

  &__term {
    @include vp-799 {
      margin-top: 10px;
    }

    &.term {
      width: auto;
    }
  }
}
