.pagination {
  @include vp-999 {
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    @include vp-539 {
      flex: 1;
    }

    &:not(:nth-last-of-type(-n + 2)):nth-last-child(-n + 5) {
      @include vp-999 {
        display: none;
      }
    }

    &:last-child {
      flex: 1;
      margin-left: 15px;

      & .pagination__link {
        width: auto;
        flex: 1;
      }
    }

    &.pagination__previous {
      &:first-child {
        flex: 1;
        margin-right: 15px;

        & .pagination__link {
          width: auto;
          flex: 1;
        }
      }
    }
  }

  &__link {
    width: 60px;
    height: 60px;
    font-size: 1.5rem;
    font-family: $ff-gotham-pro;
    font-weight: 400;
    color: $color-nobel;
    border: 1px solid $color-gainsboro;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

    @include vp-539 {
      font-size: 1.125rem;
      width: 40px;
      height: 40px;
    }

    &:hover,
    &_active {
      background-color: $color-whisper;
      border: 1px solid $color-whisper;
      color: $color-default-black;
    }
  }
}
