.catalog {
  @include vp-1239 {
    overflow: hidden;
  }

  &-main {
    & .sidebar__item {
      @include vp-1419 {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    & .price__old {
      @include vp-1419 {
        margin-left: 0;
        font-size: 1.25rem;
        text-align: center;
      }
    }
  }

  & .catalog__pagination {
    margin: 34px auto 0;
    padding: 0 15px;
    max-width: 800px !important;

    @include vp-999 {
      padding: 0 15px;
    }
  }

  & .goods__button {
    @include vp-539 {
      width: 100%;
      margin: 10px 0 0;
    }
  }

  & .goods__link {
    @include vp-539 {
      margin: 10px 0 0;
    }
  }

  & .goods__breadcrumbs {
    @include vp-539 {
      display: none;
    }
  }

  &__goods {
    width: 66.66%;
    flex: 0 0 66.66666667%;

    @include vp-1239 {
      width: 100%;
    }
  }

  &__sidebar {
    width: 33.33%;

    @include vp-1239 {
      width: 100%;
    }

    & .banner-first {
      @include vp-1239 {
        display: none;
      }
    }

    & .banner-second {
      @include vp-1239 {
        display: none;
      }
    }
  }

  &__container {
    @include vp-1239 {
      max-width: 1000px;
      margin: 0 auto;
      padding: 0 15px;
    }

    @include vp-999 {
      padding: 0;
    }
  }

  &__grid {
    display: flex;

    @include vp-1239 {
      flex-direction: column;
    }
  }

  &-card {
    & .carousel__dots {
      display: flex;
      justify-content: center;
      margin: 40px auto 0;
    }

    & .catalog__grid {
      @include vp-1239 {
        flex-direction: column-reverse;
      }
    }

    & .goods__top {
      @include vp-1239 {
        padding: 20px 15px 34px;
      }
    }

    & .banner-first {
      @include vp-1239 {
        display: flex;
        padding: 35px 15px;
      }
    }
  }

  & .banner-first {
    &.banner-down {
      @include vp-1239 {
        display: flex;
        min-height: 430px;
        padding: 70px 35px;
      }
    }
  }

  &.banner-down {
    & .banner-first__image {
      @include vp-1239 {
        width: 40%;
        right: 0;
        left: auto;
      }
    }
  }

  & .sidebar .banner-down {
    display: flex;
  }
}
