.feedback {
  padding: 80px 0 107px;
  background-image: url(../img/feedback__image_back.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @include vp-539 {
    padding: 40px 0;
  }

  &__container {
    max-width: 1015px;

    @include vp-1239 {
      max-width: 580px;
    }

    @include vp-539 {
      max-width: 100%;
    }
  }

  &__title {
    @include vp-1239 {
      font-size: 1.875rem;
    }
  }

  &__form {
    margin: 60px 0 0;

    @include vp-539 {
      margin: 20px 0 0;
    }
  }

  & .search__term.term {
    width: 100%;
  }

  & .select {
    width: 100%;
    border: 1px solid $color-ruby;
    height: 56px;
    margin: 0;
    display: block;
    background-color: $color-default-white;
    resize: none;
    font-size: 18px;
    padding: 10px 12px;
  }

  & .select-styled {
    color: $color-default-black;
  }

  & .form__button {
    width: 100%;
    height: 56px;
    text-transform: uppercase;
    font-weight: 700;
  }

  & .form__group {
    @include vp-1239 {
      max-width: 50%;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 50%;
      flex: 1 0 50%;
    }

    @include vp-539 {
      width: 100%;
      max-width: 100%;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }

  & .form__row {
    @include vp-1239 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    @include vp-799 {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }
}
