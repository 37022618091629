.owl-carousel {
  position: relative;
  display: none;
  width: 100%;
  z-index: 1;

  &.owl-drag {
    & .owl-item {
      touch-action: pan-y;
      user-select: none;
    }
  }

  & .owl-carousel {
    & .owl-nav {
      & button {
        &.owl-next {
          background: 0 0;
          color: inherit;
          border: none;
          padding: 0 !important;
          font: inherit;
        }

        &.owl-prev {
          background: 0 0;
          color: inherit;
          border: none;
          padding: 0 !important;
          font: inherit;
        }

        &.owl-dot {
          background: 0 0;
          color: inherit;
          border: none;
          padding: 0 !important;
          font: inherit;
        }
      }
    }
  }

  & .owl-dot {
    cursor: pointer;
    user-select: none;
  }

  &.owl-loaded {
    display: block;
  }

  & .owl-item {
    -webkit-tap-highlight-color: transparent;
    position: relative;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    min-height: 1px;
    float: left;
    -webkit-touch-callout: none;

    & .owl-carousel {
      & .owl-item {
        & img {
          &.owl-lazy {
            transform-style: preserve-3d;
          }
        }
      }
    }

    & .owl-lazy {
      opacity: 0;
      transition: opacity 0.4s ease;

      &:not([src]) {
        max-height: 0;
      }

      &[src^=""] {
        max-height: 0;
      }
    }

    & img {
      display: block;
      width: 100%;
    }
  }

  & .owl-dots {
    &.disabled {
      display: none;
    }
  }

  & .owl-nav {
    & .owl-next {
      cursor: pointer;
      user-select: none;
    }

    &.owl-prev {
      cursor: pointer;
      user-select: none;
    }

    &.disabled {
      display: none;
    }
  }

  & .owl-video-playing {
    & .owl-video-play-icon {
      display: none;
    }

    & .owl-video-tn {
      display: none;
    }
  }

  & .owl-wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }

  & .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }

  & .owl-stage {
    position: relative;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;

    &:after {
      visibility: hidden;
      content: ".";
      display: block;
      clear: both;
      line-height: 0;
      height: 0;
    }
  }

  &.owl-refresh {
    & .owl-item {
      visibility: hidden;
    }
  }

  &.owl-loading {
    opacity: 0;
    display: block;
  }

  &.owl-hidden {
    opacity: 0;
  }

  &.owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  &.owl-rtl {
    direction: rtl;

    & .owl-item {
      float: right;
    }
  }

  & .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  & .owl-animated-in {
    z-index: 0;
  }

  & .owl-animated-out {
    z-index: 1;
  }

  & .fadeOut {
    animation-name: fadeOut;
  }

  & .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: $color-default-black;
  }

  & .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url(owl.video.play.png) no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform 0.1s ease;

    &:hover {
      transform: scale(1.3, 1.3);
    }
  }

  & .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 0.4s ease;
  }

  & .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}

.owl-height {
  transition: height 0.5s ease-in-out;
}

.owl-stage {
  display: flex;
}

.dots,
.owl-dots,
.owl-nav {
  display: flex;
}

.owl-nav {
  position: absolute;
  top: calc(50% - 20px);
  transform: translateY(-50%);
  padding: 0 10px;
  width: 100%;
  opacity: 1;
  display: flex;
  z-index: 10;
}

.owl-nav button {
  position: absolute;
  font-size: 0;
  width: 27px;
  height: 44px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  z-index: 10;
}

.owl-nav button:hover {
  opacity: 0.8;
}

.dots,
.owl-dots {
  display: flex;
}

.owl-dots {
  justify-content: center;
  margin: 40px 0;
  flex: 1;
}

.dots .owl-dot,
.owl-dots .owl-dot {
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.owl-dot span {
  width: 15px;
  height: 15px;
  border: 1px solid $color-silver;
  background-color: transparent;
  border-radius: 50%;
}

.owl-dot.active span {
  background-color: $color-silver;
}

.owl-prev {
  @include vp-999 {
    left: 0;
  }
}

.owl-next {
  @include vp-999 {
    right: 0;
  }
}
