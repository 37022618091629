.popular {
  padding: 75px 0 90px;

  @include vp-1239 {
    padding: 20px 0 35px;
  }

  &__item {
    background-color: $color-white-smoke;
    flex: 1 0 25%;
    max-width: 25%;
    padding: 35px 35px 30px;
    border: 1px solid $color-whisper;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include vp-1239 {
      flex: 1 0 50.5%;
      max-width: 50%;
    }

    @include vp-539 {
      max-width: 100%;
      flex: 1 0 100%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__inner {
    margin-left: 20px;
  }

  &__name {
    font-size: 1.5rem;
    font-weight: 400;
    color: $color-iris-blue;
  }

  &__description {
    font-size: 1rem;
    font-weight: 400;
    color: $color-venus;
  }

  &__button {
    font-size: 1.25rem;
    margin: 20px auto 0;
    width: 245px;
    padding: 14px 10px 13px;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: $color-razzmatazz;
      color: $color-default-white;
    }
  }

  &__more {
    font-size: 1.25rem;
    display: flex;
    align-self: center;
    text-decoration: underline;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: transparent;
      color: $color-default-black;
    }
  }

  & .popular__list {
    display: flex;
    padding: 20px 0 10px;

    &--wrap {
      flex-wrap: wrap;
    }
  }
}
