.achievements {
  margin-top: 50px;
  margin-bottom: 50px;

  @include media-breakpoint-up(md) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  &__box {
    $a: &;

    margin: 0 auto 22px;
    max-width: 380px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      max-width: none;
    }

    &__pic {
      position: relative;

      & img {
        position: relative;
        z-index: 10;
        max-width: 100%;
        height: auto;

        display: block;

        margin: auto auto 0;

        @include media-breakpoint-up(md) {
        }
      }

      &:before {
        content: "";

        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;

        display: block;

        width: 100%;
        height: 80%;

        border-radius: 0 0 0 0;
      }
    }

    &__info {
      min-height: 146px;
      padding: 29px 30px;

      font-size: 16px;
      line-height: 26px;

      text-align: center;

      border: 1px solid $color-patterns-blue;
      border-top: none;
      border-radius: 0 0 0 0;
      background-color: $color-default-white;

      &__count {
        margin-bottom: 8px;

        font-weight: 900;
        font-size: 36px;
        line-height: 1;
        font-family: $ff-gotham-pro;

        letter-spacing: 0.04em;
        text-transform: uppercase;
      }
    }

    &--first {
      #{$a}__pic {
        &:before {
          background-color: $color-paua;

          @include media-breakpoint-up(lg) {
            border-radius: 0 0 0 0;
          }
        }
      }
      #{$a}__info {
        @include media-breakpoint-up(lg) {
          border-radius: 0 0 0 0;
        }
      }
    }

    &--second {
      #{$a}__pic {
        &:before {
          background-color: $color-deep-cerise;

          @include media-breakpoint-up(lg) {
            border-radius: 0 0 0 0;
          }
        }
      }
      #{$a}__info {
        @include media-breakpoint-up(lg) {
          border-right: none;
          border-left: none;
          border-radius: 0;
        }
      }
    }

    &--last {
      margin-bottom: 0;

      #{$a}__pic {
        &:before {
          background-color: $color-paua;

          @include media-breakpoint-up(lg) {
            border-radius: 0 0 0 0;
          }
        }
      }
      #{$a}__info {
        @include media-breakpoint-up(lg) {
          border-radius: 0 0 0 0;
        }
      }
    }
  }

  &--partners {
    & .achievements {
      &__box {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        padding: 0 0 22px;

        &__pic {
          img {
            height: auto;
          }
        }

        &__info {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          flex-grow: 1;
          text-align: left;
          padding: 31px 21px 24px;

          &__count-wrapper {
            margin: 0 0 18px;
          }

          &__count {
            position: relative;
            display: inline-block;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: $color-default-black;
            text-transform: capitalize;

            svg {
              position: absolute;
              top: 50%;
              right: -22px;
              transform: translate(100%, -50%);
              color: $blue;
            }
          }

          &__text {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $color-default-black;
            margin: 0 0 auto;
          }

          &__button {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            text-transform: uppercase;
            padding: 17px 24px;
            margin: 30px 0 0;
            width: 100%;

            @media (min-width: 992px) {
              width: auto;
            }
          }
        }
      }
    }
  }
}
