.overlay {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 40px;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: 0.35s ease-in-out;
  -o-transition: 0.35s ease-in-out;
  transition: 0.35s ease-in-out;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 9999;

  &.open {
    opacity: 1;
    pointer-events: inherit;
  }

  & .modal {
    background: initial;
    text-align: center;
    padding: 20px 20px;
    -webkit-box-shadow: initial;
    box-shadow: initial;
    max-width: 426px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: 0.35s ease-in-out;
    -o-transition: 0.35s ease-in-out;
    transition: 0.35s ease-in-out;
    max-height: 100vh;
    overflow-y: auto;
    text-align: left;

    & .content {
      -webkit-transform: translate(0, -10px);
      -ms-transform: translate(0, -10px);
      transform: translate(0, -10px);
      opacity: 0;
      -webkit-transition: 0.35s ease-in-out;
      -o-transition: 0.35s ease-in-out;
      transition: 0.35s ease-in-out;
    }

    & .title {
      margin-top: 0;
    }

    &.open {
      opacity: 1;
      pointer-events: inherit;

      & .content {
        -webkit-transform: translate(0, 0px);
        -ms-transform: translate(0, 0px);
        transform: translate(0, 0px);
        opacity: 1;
      }
    }
  }
}
