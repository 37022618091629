*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper {
  & input[type="checkbox"]:checked,
  & input[type="checkbox"]:not(:checked),
  & input[type="radio"]:checked,
  & input[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  & input[type="checkbox"]:checked + label,
  & input[type="checkbox"]:not(:checked) + label,
  & input[type="radio"]:checked + label,
  & input[type="radio"]:not(:checked) + label {
    position: relative;

    display: inline-block;

    padding-left: 28px;

    cursor: pointer;
  }

  & input[type="checkbox"]:checked + label:before,
  & input[type="checkbox"]:not(:checked) + label:before,
  & input[type="radio"]:checked + label:before,
  & input[type="radio"]:not(:checked) + label:before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    width: 16px;
    height: 16px;

    border: 1px solid $color-link-water;
    border-radius: 2px;
    background-color: $color-default-white;
  }

  & input[type="checkbox"]:checked + label:before,
  & input[type="checkbox"]:not(:checked) + label:before {
    border-radius: 2px;
    top: 50%;
    transform: translate(0, -50%);
  }

  & input[type="radio"]:checked + label:before,
  & input[type="radio"]:not(:checked) + label:before {
    border-radius: 100%;
  }

  & input[type="checkbox"]:checked + label:after,
  & input[type="checkbox"]:not(:checked) + label:after,
  & input[type="radio"]:checked + label:after,
  & input[type="radio"]:not(:checked) + label:after {
    content: "";

    position: absolute;

    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  & input[type="checkbox"]:checked + label:after,
  & input[type="checkbox"]:not(:checked) + label:after {
    top: 50%;
    left: 8px;
    transform: translate(-50%, -50%);

    width: 16px;
    height: 16px;

    background: url("../img/checked.svg") no-repeat 50% 50%;
  }

  & input[type="radio"]:checked + label:after,
  & input[type="radio"]:not(:checked) + label:after {
    top: 5px;
    left: 5px;

    width: 10px;
    height: 10px;

    border-radius: 100%;
    background-color: $green;
  }

  & input[type="checkbox"]:not(:checked) + label:after,
  & input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
  }

  & input[type="checkbox"]:checked + label:after,
  & input[type="radio"]:checked + label:after {
    opacity: 1;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

html {
  font-style: normal;
  font-weight: 400;
  font-size: $fz-default;
  line-height: 24px;
  font-family: $ff-placeholder;
  color: $color-default-black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // оптимизация выравнивания шрифта относительно центра строки
  text-rendering: optimizeLegibility;
  // если по прежнему есть проблемы с выравниванием
  // https://transfonter.org/ - включите настройку https://prnt.sc/12rnt6g и переконвертируйте шрифт
}

body {
  width: 100%;
  height: 100%;
  font-family: $ff-pt-sans;
  min-width: 320px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: normal;
  color: $color-default-black;
  overflow-x: hidden;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  background-color: $color-default-white;

  &.overflow {
    margin-right: 17px;
  }
}

a {
  color: $color-sapphire;
  text-decoration: none;
  cursor: pointer;
  border: none;
  background: 0 0;
  display: inline-block;
  outline: 0;
}

:root .row {
  list-style: none;
}

b {
  font-family: $ff-pt-sans;
}

p:not([class]) {
  margin: 0 0 10px;
}

ul:not([class]) {
  margin-left: 0;
}

ul:not([class]) li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

ul:not([class]) li:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: $color-maliby;
  margin-right: 10px;
}

h1,
h2 {
  margin-bottom: 15px;
  color: $color-default-black;
  margin-top: 10px;
}

h1 {
  font-size: 3rem;
  line-height: 3.4375rem;
  font-family: Geometria, Verdana, Arial, sans-serif;
  font-weight: 500;
}

h2,
h3,
h4 {
  font-weight: 900;
  font-family: $ff-gotham-pro;
}

h2 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  text-transform: uppercase;
}

h3 {
  font-size: 1.25rem;
  line-height: 1.25rem;
  margin-top: 10px;
  margin-bottom: 20px;
  color: $color-default-black;
}

ol,
ul {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

li {
  margin-bottom: 10px;
}

h4,
h5,
h6 {
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 10px;
  margin-bottom: 15px;
  color: $color-default-black;
}

h5,
h6 {
  font-family: Geometria, Verdana, Arial, sans-serif;
  font-weight: 500;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

img.lazy[data-src] {
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

textarea {
  resize: none;
}

blockquote,
body,
dd,
dl,
figcaption,
figure,
li {
  margin: 0;
}

ul[class] {
  padding: 0;
  margin: 0;
  list-style: none;
}

li:not([class]) {
  margin-bottom: 10px;
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}

article > * + * {
  margin-top: 1em;
}

button,
input,
select,
textarea {
  font: inherit;
}

// chrome autofill background removal
// если на проекте у инпутов используются разные цвета фонов\текста -
// удалите это из глобала и используйте локально с нужными цветами
// rgba не подойдет, сконвертируйте цвет в hex без прозрачности
// если в стилях уже используется box-shadow есть другое решение -
// задать к списку транзишенов `background-color 10000000s ease-out`
input:-webkit-autofill {
  box-shadow: inset 0 0 0 1000px $color-default-white;

  -webkit-text-fill-color: $color-default-black;
}

// firefox placeholder \ invalid fix + ios bdrs
input,
textarea {
  border-radius: 0;

  &::placeholder {
    opacity: 1;
  }

  &:invalid {
    box-shadow: none;
  }
}

select {
  border-radius: 0;
}

// ie11 X removal
input {
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
}

// chrome search X removal
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
}

// input[number] arrows removal
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;

  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

// ios button \ inputs reset
select,
textarea,
input:matches([type="email"], [type="number"], [type="password"], [type="search"], [type="tel"], [type="text"], [type="url"]) {
  appearance: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  appearance: none;
  cursor: pointer;
  border: none;
  background: 0 0;
  display: inline-block;
  outline: 0;
}
