.tabs {
  color: $color-default-black;

  &__link {
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
    color: inherit;

    @include vp-1419 {
      font-size: 1rem;
    }

    @include vp-799 {
      width: 100%;
    }
  }

  &__item {
    margin: 0 25px;
    padding: 21px 10px;
    color: $color-nobel;
    position: relative;
    cursor: pointer;
    transition: color 0.3s ease;

    @include vp-799 {
      margin: 0 10px;
    }

    @include vp-539 {
      margin: 0 15px;
      white-space: nowrap;
    }

    &:hover {
      color: $color-default-black;

      &:before {
        position: absolute;
        height: 1px;
        background-color: $color-ruby;
        width: 100%;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
      }
    }

    &_active {
      color: $color-default-black;

      &:before {
        position: absolute;
        height: 1px;
        background-color: $color-ruby;
        width: 100%;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
      }
    }
  }

  &__button {
    align-items: center;
    justify-content: center;
    width: 70px;
    display: none;
    transition: background-color 0.3s ease;

    @include vp-799 {
      display: none;
    }

    &:hover {
      background-color: $color-white-smoke;
    }

    &_left {
      border-right: 1px solid $color-whisper;
    }

    &_right {
      border-left: 1px solid $color-whisper;
    }
  }

  & .tabs__list {
    display: flex;
    justify-content: center;

    @include vp-799 {
      margin: 0 auto;
    }
  }
}
