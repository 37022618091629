.features {
  & .features__item {
    flex-grow: 1;
    z-index: 1;
    position: relative;
    min-height: 400px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 0 40px 23px;
    color: $color-default-white;
    text-align: center;

    @include vp-1419 {
      padding: 0 10px 10px;
    }

    @include vp-539 {
      flex: 1 0 100%;
      max-width: 100%;
    }
  }

  &__item {
    @include vp-1239 {
      flex: 1 0 50%;
      max-width: 50%;
    }
  }

  & .features__image {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: top;
    z-index: -1;
  }

  &__name {
    font-family: $ff-gotham-pro;
    font-size: 1.5625rem;
    line-height: 1;
    font-weight: 900;
    text-transform: uppercase;
  }

  &__text {
    margin: 8px 0 0;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  &--list {
    @include vp-1239 {
      flex-wrap: wrap;
    }
  }
}
